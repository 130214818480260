import {
  EMPTY_TOKEN,
  useBalanceHub,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/aptos-defi/common';
import { getLPTokenAddress } from '@aries/aptos-defi/common/aries-sdk';
import { LendingAppDefine } from '@aries/lending-fe-hooks/adapter';
import { createGlobalStore } from '@aries/shared/deps';
import { formatQuoteValue } from '@aries/shared/utils';
import { formatPercentage } from '@aries/shared/utils/quote-value';
import Big from 'big.js';
import { compact, isEmpty, isNil, noop } from 'lodash';
import { useMemo } from 'react';
import { ZUSDT_ADDRESS } from '../config';
import { useWallet } from '../wallet';
import { claimRewards, getReservesActions } from './actions';
import { useFaucet, useProfileHub, useReserves } from './data';
import { exitEmode } from './data/profiles/actions';
import { useUnhealthyProfiles } from './liquidate';
/**
 * Lending app impl on aptos blockchain
 */

const deprecatedReserveIds = [
  ZUSDT_ADDRESS,
  '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T',
  '0xdd89c0e695df0692205912fb69fc290418bed0dbe6e4573d744a6d5e6bab6c13::coin::T',
  '0x84d7aeef42d38a5ffc3ccef853e1b82e4958659d16a7de736a29c55fbbeb0114::staked_aptos_coin::StakedAptosCoin',
];

const useAptosLendingAppImpl: () => LendingAppDefine = () => {
  const { env } = useProviderHub();
  const { balanceMap } = useBalanceHub();
  const { tokenMap } = useTokenInfoHub();
  const walletCtx = useWallet();
  const { reserves: reserveMetas, loading } = useReserves();
  const {
    initMainProfile,
    changeProfile,
    addProfile,
    currentProfile,
    currentProfileKey,
    profileList,
    profileLoading,
  } = useProfileHub();

  const { faucets } = useFaucet();

  const reserves = useMemo(
    () =>
      reserveMetas.map(({ coinAddress, ...reserve }) => {
        const tokenInfo = tokenMap[coinAddress] ?? EMPTY_TOKEN;
        const {
          reserveConfig: {
            borrowLimit,
            depositLimit,
            allowCollateral,
            allowRedeem,
            loanToValue,
          },
          totalBorrowed,
          supplyApyPct,
          borrowApyPct,
          supplyRewardApyPct,
          borrowRewardApyPct,
          totalDeposited,
          marketCap,
          borrowFeePct,
          withdrawFeePct,
          maxInterestRatePct,
          optimalInterestRatePct,
          optimalUtilizationPct,
          flashLoanFeePct,
          borrowFactorPct,
          minInterestRatePct,
          utilizationRatio,
        } = reserve;

        const lpCoinAddress = getLPTokenAddress(coinAddress);

        const rawActions = getReservesActions({
          ...reserve,
          coinAddress,
          unwrappedFaAddress: reserve.rawReserve.unwrappedFaAddress,
        });

        const actions: LendingAppDefine['reserves'][number]['actions'] = {
          withdraw: (amount, isMax) =>
            rawActions.withdraw(tokenInfo.toLamports(amount), isMax),
          repay: (amount, isMax) =>
            rawActions.repay(tokenInfo.toLamports(amount), isMax),
          deposit: amount =>
            rawActions.deposit(tokenInfo.toLamports(amount)),
          borrow: amount =>
            rawActions.borrow(tokenInfo.toLamports(amount)),
        };

        const calcApyProps = (baseApyPct: Big, rewardApyPct: Big) => {
          const totalApyPct = baseApyPct.add(rewardApyPct);
          return {
            baseApy: {
              base: `${baseApyPct.toFixed(2)}%`,
              isPositive: baseApyPct.gte(0),
              num: baseApyPct.toNumber(),
            },
            rewardApy: !rewardApyPct.eq(0)
              ? {
                  // It might be very large
                  base: formatPercentage(rewardApyPct),
                  isPositive: rewardApyPct.gte(0),
                  num: rewardApyPct.toNumber(),
                }
              : undefined,
            totalApy: {
              base: formatPercentage(totalApyPct),
              isPositive: totalApyPct.gte(0),
              num: totalApyPct.toNumber(),
            },
          };
        };

        const findRewardAssets = (
          farmingType: 'DepositFarming' | 'BorrowFarming',
        ) =>
          compact(
            reserve.rawFarms
              .filter(
                ({ reserveCoinAddress, rewardCondition }) =>
                  reserveCoinAddress === coinAddress &&
                  rewardCondition === farmingType,
              )
              .map(({ rewardCoinAddress, rewardApy }) => ({
                ...tokenMap[rewardCoinAddress],
                rewardApy: formatPercentage(rewardApy),
              })),
          );

        return {
          coinAddress,
          id: coinAddress,
          rawReserve: reserve,
          meta: {
            loanToValue: `${loanToValue}%`,
            loanToValuePct: loanToValue,
            allowCollateral,
            allowWithdraw: allowRedeem,
            lpCoinAddress,
            coinAddress,
            borrowLimit: tokenInfo.toAmountStr(borrowLimit, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            borrowLimitNum: tokenInfo.toAmount(borrowLimit).toNumber(),
            depositLimit: tokenInfo.toAmountStr(depositLimit, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            depositLimitNum: tokenInfo.toAmount(depositLimit).toNumber(),
            borrowFeePct: borrowFeePct.toNumber(),
            withdrawFeePct: withdrawFeePct.toNumber(),
            maxInterestRatePct,
            minInterestRatePct,
            optimalInterestRatePct,
            optimalUtilizationPct,
            flashLoanFeePct,
            supplyRewardApyPct,
            borrowRewardApyPct,
            borrowFactorPct,
            deprecated: deprecatedReserveIds.includes(coinAddress),
          },
          status: {
            marketCap: tokenInfo.lamportsToBalance(marketCap),
            utilizationRatio: utilizationRatio.mul(100).toNumber(),
          },
          asset: tokenInfo,
          deposit: {
            ...calcApyProps(supplyApyPct, supplyRewardApyPct),
            rewardAssets: findRewardAssets('DepositFarming'),
            balance: tokenInfo.lamportsToBalance(totalDeposited),
            reachedDepositLimit: totalDeposited.gte(depositLimit),
          },
          borrow: {
            // Borrow rewards contribute negative borrowing APY
            ...calcApyProps(borrowApyPct, Big(0).sub(borrowRewardApyPct)),
            rewardAssets: findRewardAssets('BorrowFarming'),
            balance: tokenInfo.lamportsToBalance(totalBorrowed),
          },
          actions,
        };
      }),

    [tokenMap, reserveMetas],
  );

  const reserveWithProfile: LendingAppDefine['reserves'] = useMemo(() => {
    return reserves.map(
      ({ coinAddress, status: reserveStatus, ...reserve }) => {
        const tokenInfo = reserve.asset;

        const walletAmount = tokenInfo.lamportsToBalance(
          balanceMap[coinAddress]?.amount ?? Big(0),
        );

        const depositedAsset =
          currentProfile?.getDepositedLamports(coinAddress) ?? Big(0);

        const borrowedLamports =
          currentProfile?.getBorrowedLamports(coinAddress) ?? Big(0);

        const withdrawableAmount =
          currentProfile?.getWithdrawableAmount(coinAddress) ?? Big(0);

        const borrowableAmount =
          currentProfile?.getBorrowableAmount(coinAddress) ?? Big(0);

        const depositable =
          currentProfile?.getDepositableAmount(
            coinAddress,
            walletAmount.lamports,
          ) ?? Big(0);

        const loanToValuePct =
          currentProfile?.getLoanToValuePct(reserve.rawReserve) ??
          reserve.rawReserve.loanToValuePct;
        const liquidationThreshold =
          currentProfile?.getLiquidationThreshold(reserve.rawReserve) ??
          reserve.rawReserve.liquidationThreshold;
        const liquidationBonusPct =
          currentProfile?.getLiquidationBonusPct(reserve.rawReserve) ??
          reserve.rawReserve.liquidationBonusPct;

        return {
          ...reserve,
          meta: {
            ...reserve.meta,
            loanToValue: `${loanToValuePct}%`,
            loanToValuePct,
            liquidationThreshold: `${liquidationThreshold}%`,
            liquidationBonus: `${liquidationBonusPct}%`,
            reserveInEmode: !isEmpty(
              reserve.rawReserve.rawReserve?.emodeIds ?? [],
            ),
          },
          wallet: walletAmount,
          summary: {
            marketCap: reserveStatus.marketCap,
            utilization: reserveStatus.utilizationRatio,
            ofUser: {
              totalMarginValueUSD: `$${currentProfile?.totalMarginUSD?.toFixed(
                3,
              )}`,
            },
          },
          deposit: {
            ...reserve.deposit,
            ofUser: {
              deposited: tokenInfo.lamportsToBalance(depositedAsset),
              withdrawable: tokenInfo.toAmount(withdrawableAmount),
              depositable: tokenInfo.toBalance(depositable),
            },
          },
          borrow: {
            ...reserve.borrow,
            ofUser: {
              available: tokenInfo.toBalance(borrowableAmount),
              borrowed: tokenInfo.lamportsToBalance(borrowedLamports),
            },
            shouldExitEmode:
              !isNil(currentProfile) &&
              !currentProfile.isReserveBorrowable(reserve.rawReserve),
          },
        };
      },
    );
  }, [reserves, balanceMap, currentProfile]);

  const summary = useMemo(() => {
    let marketSize = Big(0);
    let borrowed = Big(0);

    reserves.forEach(reserve => {
      marketSize = marketSize.add(reserve.deposit.balance.valueUSDNum);
      borrowed = borrowed.add(reserve.borrow.balance.valueUSDNum);
    });

    return {
      marketSize: formatQuoteValue(marketSize.toNumber()),
      borrowed: formatQuoteValue(borrowed.toNumber()),
      lentOut: marketSize.eq(0)
        ? '-'
        : `${borrowed.div(marketSize).mul(100).toFixed(1)}%`,
    };
  }, [reserves]);

  const profiles = profileList.map(
    ({
      address,
      name,
      availableMarginUSD,
      totalDepositedUSD,
      totalLoanUSD,
      networth,
      loanList,
      borrowPower,
      depositList,
      riskFactorPct,
      totalApyPct,
      rewardList,
      totalRewardUSD,
      profileEmode,
    }) => ({
      id: address,
      name,
      summary: {
        apy: totalApyPct,
        isApyPositive: totalApyPct > 0,
        depositedValueUSD: `$${totalDepositedUSD.toFixed(2)}`,
        borrowedValueUSD: `$${totalLoanUSD.toFixed(2)}`,
        availableMarginUSD: `$${
          availableMarginUSD.lt(0) ? 0 : availableMarginUSD.toFixed(2)
        }`,
        networth: `$${networth.toFixed(2)}`,
        borrowingPowerPct: borrowPower,
        loans: loanList,
        deposits: depositList,
        rewards: rewardList,
        rewardValueUSD: `$${totalRewardUSD.toFixed(2)}`,
        riskFactorPct,
        emodeId: profileEmode?.emodeId,
      },
      claimRewards: (reserveCoinAddress: string) =>
        claimRewards(name, reserveCoinAddress),
    }),
  );

  const { unhealthyProfiles, loading: profileListLoading } =
    useUnhealthyProfiles();

  return {
    reserves: reserveWithProfile,
    reserveLoading: loading,
    reserveEmpty: false,
    unhealthyProfiles,
    obligationEmpty: !loading && unhealthyProfiles.length === 0,
    obligationLoading:
      unhealthyProfiles.length === 0 && profileListLoading,
    triggerRefreshObligation: noop,
    profile: {
      initProfile: initMainProfile,
      addProfile,
      changeProfile,
      exitEmode: () => exitEmode(),
      initialized: (profileList.length ?? 0) > 0,
      list: profiles,
      currentProfile: profiles.find(p => p.id === currentProfileKey),
      currentProfileId: currentProfileKey,
      currentProfileLoading: profileLoading,
    },
    summary,
    faucets,
    env,
    wallet: walletCtx,
  };
};

export const [useAptosLendingApp, getAptosLendingApp] = createGlobalStore(
  useAptosLendingAppImpl,
);
