import Big from 'big.js';
import { Asset, BaseAsset } from './types';

export * from './types';

// To avoid Big.toString() get string such as 1e23
Big.PE = 50;

export const createAsset = (base: BaseAsset, price: number): Asset => {
  return {
    ...base,
    ...getPrice(price),
    ...getFormatUtils(base.decimals, new Big(price), base.symbol),
  };
};

export const getPrice = (price: number = 1) => {
  return {
    price: Big(price),
    priceUSD: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price),
    priceUSDNum: price,
  };
};

export const getFormatUtils = (
  decimals: number,
  price: Big,
  symbol: string,
) => {
  const multiplier = 10 ** decimals;

  const obj: Pick<
    Asset,
    | 'toUSDValue'
    | 'toUSDValueStr'
    | 'toAmount'
    | 'toAmountStr'
    | 'toLamports'
    | 'formatAmount'
    | 'amountToUSD'
    | 'amountToUSDStr'
    | 'toBalance'
    | 'lamportsToBalance'
  > = {
    toUSDValue: lamports => {
      return lamports.mul(price).div(multiplier).toNumber();
    },
    toUSDValueStr: lamports => {
      const USD = lamports.mul(price).div(multiplier).toNumber();

      const displayStr = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(USD);

      return displayStr;
    },
    toAmount: lamports => {
      Big.DP = 18;
      return lamports.div(multiplier).round(decimals, Big.roundDown);
    },
    toAmountStr: (lamports, options) => {
      try {
        const amount = lamports.div(multiplier).toNumber();

        const displayStr = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          ...options,
        }).format(amount);

        return `${displayStr}${options?.hideSymbol ? '' : ` ${symbol}`}`;
      } catch (err) {
        return `- ${symbol}`;
      }
    },
    toLamports: amount => Big(amount).mul(multiplier).round(0),
    formatAmount: (amount, options) => {
      const displayStr = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: decimals,
        minimumSignificantDigits: 1,
        ...options,
      }).format(amount);

      return `${displayStr}${options?.hideSymbol ? '' : ` ${symbol}`}`;
    },
    amountToUSD: amount => price.mul(amount).toNumber(),
    amountToUSDStr: (amount, options) => {
      const displayStr = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 2,
        minimumSignificantDigits: 1,
        maximumFractionDigits: 3,
        style: 'decimal',
        currency: 'USD',
        ...options,
      }).format(amount);

      return `${displayStr}`;
    },
    toBalance: amount => {
      const lamports = new Big(multiplier).mul(amount);

      return {
        amount: obj.toAmountStr(lamports),
        amountNum: obj.toAmount(lamports).toNumber(),
        amountBig: obj.toAmount(lamports),
        valueUSD: obj.toUSDValueStr(lamports),
        lamports,
        valueUSDNum: obj.toUSDValue(lamports),
      };
    },
    lamportsToBalance: lamports => ({
      amount: obj.toAmountStr(lamports),
      amountNum: obj.toAmount(lamports).toNumber(),
      amountBig: obj.toAmount(lamports),
      valueUSD: obj.toUSDValueStr(lamports),
      lamports,
      valueUSDNum: obj.toUSDValue(lamports),
    }),
  };

  return obj;
};
