import { initI18n } from '@aries/shared/locale';
import en_US from '@aries/shared/locale/aptos/en_US.json';
import korean from '@aries/shared/locale/aptos/ko.json';
import russian from '@aries/shared/locale/aptos/ru.json';
import turkey from '@aries/shared/locale/aptos/tr.json';
import zh_TW from '@aries/shared/locale/aptos/zh_TW.json';

initI18n({
  en: { translations: en_US },
  zh: { translations: zh_TW },
  ko: { translations: korean },
  ru: { translations: russian },
  tr: { translations: turkey },
});
