const MAX_RETRIES = 3;

export const retryPost = async (
  postOperation: () => Promise<void>,
  retries = MAX_RETRIES,
) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await postOperation();
      return result;
    } catch (error) {
      if (attempt < retries) {
        // eslint-disable-next-line no-console
        console.warn(
          `error posting data ${error}, retrying (${attempt}/${retries})...`,
        );
      } else {
        throw error;
      }
    }
  }

  return undefined;
};
