export const WALLET_POSITION_MAP: Record<string, number | undefined> = {
  'Continue with Google': 0,
  Petra: 100,
  'OKX Wallet': 200,
  'Bitget Wallet': 300,
  'Bybit Wallet': 400,
  'Mizu Wallet': 500,
  'Pontem Wallet': 600,
  Martian: 700,
  Rise: 800,
  Blocto: 900,
  TrustWallet: 1000,
  Fewcha: 1100,
  Rimosafe: 1150,
  MSafe: 1200,
};
