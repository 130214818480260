import {
  getAriesProgram,
  getBalanceHub,
  getTokenInfoHub,
  hasEnoughGasOrThrow,
} from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { getAptosLendingApp } from '@aries/aptos-defi/lending';
import {
  getCurrentProfile,
  getProfileHub,
  getReserves,
} from '@aries/aptos-defi/lending/data';
import { getPythUpdatePayload } from '@aries/aptos-defi/oracle';
import { withSendTxNotify } from '@aries/aptos-defi/utils';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import { bigMin, delay } from '@aries/shared/utils';
import Big from 'big.js';
import { FarmingType } from '../config';

const U64_MAX = Big(2).pow(64).sub(1);

export const getReservesActions = ({
  coinAddress,
  unwrappedFaAddress,
}: {
  coinAddress: string;
  unwrappedFaAddress?: string;
}) => {
  const env = getAptosLendingApp()?.env!;

  const c = () => {
    hasEnoughGasOrThrow();
    return getAriesSDK().controller;
  };

  const extern = () => {
    hasEnoughGasOrThrow();
    return getAriesSDK().extern;
  };

  const refresh = async () => {
    await delay(800);
    getBalanceHub()?.refresh();
    getReserves()?.refresh();
    getProfileHub()?.refreshCurrentProfile();
  };

  const deposit = withSendTxNotify(async (lamports: Big) => {
    const res = await c()
      .deposit(
        {
          amount: lamports,
          profile_name: getCurrentProfile().name,
          repay_only: false,
        },
        { Coin0: coinAddress },
      )
      .execute();

    refresh();
    return res;
  });

  const depositFa = async (lamports: Big) => {
    const userFaBalance =
      getBalanceHub()?.balanceMap[unwrappedFaAddress ?? ''].amount ??
      lamports;

    const depositFaLamports = bigMin(userFaBalance, lamports);
    const depositWCoinLamports = lamports.minus(userFaBalance);

    let successed1 = false;
    let successed2 = false;
    const needDepositFa = depositFaLamports.gt(0);
    const needDepositWcoin = depositWCoinLamports.gt(0);

    if (needDepositFa) {
      successed1 = await withSendTxNotify(async () => {
        const res = await c()
          .depositFa(
            {
              amount: depositFaLamports,
              profile_name: getCurrentProfile().name,
            },
            { WCoin: coinAddress },
          )
          .execute();

        if (needDepositWcoin) {
          await delay(3000);
        }
        return res;
      })();
    }

    if (needDepositWcoin) {
      successed2 = await withSendTxNotify(async () => {
        return c()
          .deposit(
            {
              amount: depositWCoinLamports,
              profile_name: getCurrentProfile().name,
              repay_only: false,
            },
            { Coin0: coinAddress },
          )
          .execute();
      })();
    }

    if (successed1 || successed2) {
      refresh();
    }

    return (
      (successed1 || !needDepositFa) && (successed2 || !needDepositWcoin)
    );
  };

  const tryWithdraw = async (
    profile: ReturnType<typeof getCurrentProfile>,
    ...params: Parameters<ReturnType<typeof c>['withdraw']>
  ) => {
    const [args, typeArgs] = params;
    try {
      const quickPassTx = c().withdraw(args, typeArgs);
      const simulateRes = await quickPassTx.simulate();
      if (simulateRes.success) {
        return quickPassTx.execute();
      }
      // eslint-disable-next-line no-console
      console.log('🚀 ~ simulateRes:', simulateRes);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ simulateRes ~ caught error:', error);
    }

    const vaas = await getPythUpdatePayload(env, profile, [
      params[1].Coin0,
    ]);

    return extern()
      .withdraw({ ...args, pyth_vaas: vaas }, typeArgs)
      .execute();
  };

  const withdraw = withSendTxNotify(
    async (lamports: Big, isMax?: boolean) => {
      const profile = getCurrentProfile();
      const res = await tryWithdraw(
        profile,
        {
          amount: isMax ? U64_MAX : lamports,
          profile_name: profile.name,
          allow_borrow: false,
        },
        { Coin0: coinAddress },
      );
      refresh();
      return res;
    },
  );

  const withdrawFa = withSendTxNotify(
    async (lamports: Big, isMax?: boolean) => {
      const profile = getCurrentProfile();
      const res = await c()
        .withdrawFa(
          {
            amount: isMax ? U64_MAX : lamports,
            profile_name: profile.name,
            allow_borrow: false,
          },
          { WCoin: coinAddress },
        )
        .execute();
      refresh();
      return res;
    },
  );

  const borrow = withSendTxNotify(async (lamports: Big) => {
    const borrowedUSD =
      getTokenInfoHub()?.tokenMap[coinAddress].toUSDValue(lamports) ?? 0;

    if (borrowedUSD < 5) {
      throw new Error('You need to borrow at least $5 worth of assets.');
    }

    const profile = getCurrentProfile();
    const res = tryWithdraw(
      profile,
      {
        amount: lamports,
        profile_name: profile.name,
        allow_borrow: true,
      },
      { Coin0: coinAddress },
    );
    refresh();
    return res;
  });

  const borrowFa = withSendTxNotify(async (lamports: Big) => {
    const borrowedUSD =
      getTokenInfoHub()?.tokenMap[coinAddress].toUSDValue(lamports) ?? 0;

    if (borrowedUSD < 5) {
      throw new Error('You need to borrow at least $5 worth of assets.');
    }

    const profile = getCurrentProfile();
    const res = c()
      .withdrawFa(
        {
          amount: lamports,
          profile_name: profile.name,
          allow_borrow: true,
        },
        { WCoin: coinAddress },
      )
      .execute();
    await delay(1000);
    setTimeout(() => {
      getProfileHub()?.refreshCurrentProfile();
    }, 4000);
    refresh();
    return res;
  });

  const repay = withSendTxNotify(
    async (lamports: Big, isMax?: boolean) => {
      const res = await c()
        .deposit(
          {
            amount: isMax ? U64_MAX : lamports,
            profile_name: getCurrentProfile().name,
            repay_only: true,
          },
          { Coin0: coinAddress },
        )
        .execute();

      refresh();

      return res;
    },
  );

  const repayFa = async (lamports: Big, isMax?: boolean) => {
    try {
      const borrowedLamports =
        getCurrentProfile().getBorrowedLamports(coinAddress);
      let totalRepayAmount = lamports;
      if (isMax) {
        const userTotalBalance =
          getBalanceHub()?.balanceMap[coinAddress].amount ?? lamports;
        totalRepayAmount = bigMin(userTotalBalance, borrowedLamports);
      }

      const userFaBalance =
        getBalanceHub()?.balanceMap[unwrappedFaAddress ?? ''].amount ??
        totalRepayAmount;
      const reapyFaLamports = bigMin(userFaBalance, totalRepayAmount);
      const repayWCoinLamports = totalRepayAmount.minus(userFaBalance);

      let successed1 = false;
      let successed2 = false;
      const needRepayFa = reapyFaLamports.gt(0);
      const needRepayWcoin = repayWCoinLamports.gt(0);

      if (needRepayFa) {
        successed1 = await withSendTxNotify(async () => {
          const res = await c()
            .depositFa(
              {
                amount: reapyFaLamports,
                profile_name: getCurrentProfile().name,
              },
              { WCoin: coinAddress },
            )
            .execute();

          if (needRepayWcoin) {
            await delay(3000);
          }
          return res;
        })();
      }

      if (needRepayWcoin) {
        successed2 = await withSendTxNotify(async () => {
          return c()
            .deposit(
              {
                amount: isMax ? U64_MAX : repayWCoinLamports,
                profile_name: getCurrentProfile().name,
                repay_only: true,
              },
              { Coin0: coinAddress },
            )
            .execute();
        })();
      }

      if (successed1 || successed2) {
        refresh();
      }

      return (
        (successed1 || !needRepayFa) && (successed2 || !needRepayWcoin)
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ repayFa ~ error:', error);
      return false;
    }
  };

  return {
    deposit: unwrappedFaAddress ? depositFa : deposit,
    withdraw: unwrappedFaAddress ? withdrawFa : withdraw,
    borrow: unwrappedFaAddress ? borrowFa : borrow,
    repay: unwrappedFaAddress ? repayFa : repay,
  };
};

export type ReserveActions = ReturnType<typeof getReservesActions>;

export const initAries = withSendTxNotify(async () => {
  const wallet = getWalletCtx()?.walletAddress;
  if (!wallet) throw new Error('Please connect your wallet.');

  const res = await getAriesSDK()
    .controller.init({
      admin_addr: wallet,
    })
    .execute();

  return res;
});

export const addReserve = withSendTxNotify(async (coinAddress: string) => {
  const res = await getAriesSDK()
    .controller.addReserve({
      Coin0: coinAddress,
    })
    .execute();

  if (res.success) {
    getReserves()?.refresh();
  }

  return res;
});

export const updateReserveConfig = withSendTxNotify(
  async (coinAddress: string, val: any) => {
    const res = await getAriesSDK()
      .controller.updateReserveConfig(val, {
        Coin0: coinAddress,
      })
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const updateInterestConfig = withSendTxNotify(
  async (coinAddress: string, val: any) => {
    const res = await getAriesSDK()
      .controller.updateInterestRateConfig(val, {
        Coin0: coinAddress,
      })
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const claimRewards = withSendTxNotify(
  async (profileName: string, rewardCoinAddress: string) => {
    const res = await getAriesSDK()
      .extern.claimRewards(
        { profile_name: profileName },
        { RewardCoin: rewardCoinAddress },
      )
      .execute();

    if (res.success) {
      await getProfileHub()?.refreshCurrentProfile();
    }

    return res;
  },
);

export const addReserveReward = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    amount,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    amount: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const lamports = rewardAsset.toLamports(amount.toNumber());
    const ariesSdk = getAriesSDK();
    try {
      await ariesSdk.reward_container.RewardContainer.getByType.fromProgram(
        {
          CoinType: rewardCoin,
        },
      );
    } catch (e) {
      await ariesSdk.controller
        .initRewardContainer({
          Coin0: rewardCoin,
        })
        .execute();
    }

    const res = await getAriesSDK()
      .controller.addReward(
        { amount: lamports },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const updateRewardRate = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    rewardAmountPerday,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    rewardAmountPerday: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const rewardPerDay = rewardAsset.toLamports(
      rewardAmountPerday.toNumber(),
    );
    const res = await getAriesSDK()
      .controller.updateRewardRate(
        {
          reward_per_day: rewardPerDay,
        },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const removeReward = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    amount,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    amount: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const lamports = rewardAsset.toLamports(amount.toNumber());
    const res = await getAriesSDK()
      .controller.removeReward(
        {
          amount: lamports,
        },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);
